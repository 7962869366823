body {
  color: #353a47
}
h1, h2, h3, h4, h5, h6, span, p {
  color: #353a47
}

a {
  color: orange;
  cursor: pointer;
}

.home-slider {
  
}